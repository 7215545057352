#Footer {
    background-color: #1484D4;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#Footer img {
    width: 5vh;
    transition: 0.2s;
}

#Footer .reseaux {
    display: flex;
    width: 25vh;
    justify-content: space-around;
}

#Footer img:hover {
    transform: scale(1.15);
}