body {
    background-color: white;
    scroll-behavior: smooth;
}

p, h1, h2, h3, h4, h5{
    font-family: "Poppins";
}

.copyright {
    background-color: #1484D4;
    display: flex;
    justify-content: center;
    color: white;
}