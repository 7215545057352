#parcours #title_parcours {
    color: #1484D4;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10vh;
}

.line-container {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
}

.middle-line {
    border: 2px #1484D4 solid;
    height: 115vh;
}

.content_parcours {
    display: flex;
    position: relative;
    justify-content: center
    
}

.content_parcours .item {
    color: #ffffff;
    margin-top: 5vh;
    padding: 4vh 4vw;
    background-color: #1484D4;
    border-radius: 20px;
    border-radius: 20px;
    box-shadow:  7px 7px 14px #d9d9d9,
                 -7px -7px 14px #ffffff;
}

.item .years {
    margin-bottom: 5vh;
}

.item .activity {
    margin-bottom: 5vh;
    padding: 0vh -15vh;
    font-weight: 600;
    text-align: center;
}

.item .activity2 {
    padding: 0vh -15vh;
    font-weight: 600;
    text-align: center;
}

.item .description {
    text-align: center;
    margin-bottom: 5vh;
}

@media (max-width: 768px) {
    #parcours #title_parcours {
        margin-bottom: 5vh;
    }

    .content_parcours .item {
        width: 80vw;
    }

    .middle-line {
        border: 2px #1484D4 solid;
        height: 100vh;
    }
}