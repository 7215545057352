#presentation {
    margin-bottom: 10vh;
    overflow: hidden;
}

#presentation_content {
    padding: 10vh;
    padding-top: 20vh
}

#presentation_content h1, #presentation_content h2 {
    display: inline;
    font-weight: 600;
    font-family: "Poppins";
}

#presentation_content h3, #presentation_content h4 {
    font-family: "Poppins";
    font-weight: 400;
}

.text {
    transition: 0.5s;
    transform: translateX(50%);
}

.animated_text {
    transition: 0.5s;
    transform: translateX(0%);
}

.btn-transition h2 {
    background-color: #1484D4;
}

.arrow {
    margin-top: 10vh;
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation-duration: 2s;
    animation-name: moveArrow;
    animation-iteration-count: infinite;
}

.down-arrow {
    margin-top: -10px;
    height: 6vh;
    width: 6vw;
    
}

@media (max-width: 768px) {
    #presentation_content {
        padding-top: 15vh;
        padding-left: 10vw;
        padding-right: 10vw;
    }
    #presentation_content h3 {
        margin-bottom: 3vh;
    }
    .arrow {
        margin-top: -5vh;
    }
    .down-arrow {
        width: 60vw;
    }
}

@keyframes moveArrow {
    0% {
        transition: 0.2s;
        transform: translateY(0%)
    }
    50% {
        transition: 0.2s;
        transform: translateY(50%)
    }
    100% {
        transition: 0.2s;
        transform: translateY(0%)
    }
}

@keyframes myAnim {
    0%,
    50%,
    100% {
      opacity: 1;
    }
  
    25%,
    75% {
      opacity: 0;
    }
  }