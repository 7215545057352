#contact {
    margin-top:10vh;
    text-align:center;
    color: #1484D4;
    margin-bottom:10vh;

}

#contact h1 {
    margin-bottom: 10vh;
    font-weight: 600;
}

.contact-content{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.formulaire {
    border-radius: 20px;
background: #ffffff;
box-shadow:  5px 5px 26px #d4d4d4,
             -5px -5px 26px #ffffff;
    margin-left: -10vh;
    padding: 2vh 5vh;
}

.formulaire input, .formulaire textarea{
    width: 60vh;
    border-color: #1484D4;
    border-radius: 10px;
}

.formulaire input:focus, .formulaire textarea:focus{
    transition:0.1s;
    border-width: 2px;
    border-color: #1484D4;
}

.formulaire label {
    color: #1484D4;
    font-family: 'Poppins';
    font-weight: 400;
}

#form-title {
    margin-top: 2vh;
    margin-bottom: 5vh;
}

.moncv {
    margin-left: -10vh;
    margin-top: 10vh;
}

#cv-title {
    margin-bottom: 15vh;
}

.moncv img {
    width:20vh;
    height:20vh;
    padding: 3vh;
    border-radius: 20px;
    background: #ffffff;
    box-shadow:  5px 5px 26px #d4d4d4,-5px -5px 26px #ffffff;
    transition: 0.2s;
}

.moncv img:hover {
    transform:scale(1.05);
}

.formulaire button {
    color: #ffffff;
    background-color: #1484D4;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding: 2vh 7vh;
    border-radius: 10px;
    box-shadow:  5px 5px 12px #e8e8e8,
    -5px -5px 12px #ffffff;
    transition: 0.2s;
    font-family: 'Poppins';
    font-weight: 600;
}

.formulaire button:hover {
    transform: scale(1.05);
}

.formulaire #subBtn {
    width: 20vh;
    height: 6vh;
    color: #ffffff;
    background-color: #1484D4;
    border:none !important;
    border-radius: 10px;
    box-shadow:  5px 5px 12px #e8e8e8,
    -5px -5px 12px #ffffff;
    transition: 0.2s;
    font-family: 'Poppins';
    font-weight: 600;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.formulaire #subBtn:hover {
    transform: scale(1.05);
}

.formulaire #spinner {
    display: none;
    justify-content: center;
}

#response {
    display: none;
    width: 60vh;
    padding: 3vh 1vh;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .formulaire {
        width: 45vh;
    }

    .contact-content {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: center;
    }

    .formulaire input {
        width: 35vh;
    }

    #cv-title {
        margin-bottom: 8vh;
    }

    .formulaire {
        margin-left: 0vh;
    }

    .moncv {
        margin-left: 0vh;
    }
}