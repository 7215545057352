#experiences {
    margin-top: 25vh;
}

.middle-line2 {
    border: 2px #1484D4 solid;
    height: 130vh;
}

#experiences #title_experiences {
    color: #1484D4;
    text-align: center;
    font-weight: 600;
    margin-bottom: 10vh;
}

#content_exp {
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

#content_exp .item {
    color: #ffffff;
    background-color: #1484D4;
    padding: 4vh 4vw;
    margin-top: 5vh;
    border-radius: 20px;
    border-radius: 20px;
    box-shadow:  7px 7px 14px #d9d9d9,
                 -7px -7px 14px #ffffff;
}

@media (max-width: 768px) {
    #experiences {
        margin-top: 20vh;
    }

    .middle-line2 {
        height: 108vh;
    }
}