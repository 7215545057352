#projet {
    background-color: #1484D4;
    color: #FFFFFF;
    margin-top: 30vh;
    padding-bottom: 5vh;
    padding-top: 15vh;
    justify-content: center;
    text-align: center;
}

#projet #projet_title {
    margin-bottom: 15vh;
    font-weight: 600;
}

#projet #projet-content h1{
    font-weight:400;
}

#projet .list-projet {
    margin-top: 5vh;
    display: flex;
    flex-direction: row;
    margin-bottom: 20vh;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

#projet .item {
    color: #FFFFFF;
    padding: 1vh 1vw;
    height: 40vh;
    width: 40vw;
    border-radius: 2vh;
background: #1484D4;
box-shadow:  5px 5px 16px #1170b4,
             -5px -5px 16px #1798f4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    transition: 0.2s;
    margin-bottom: 5vh;
}

#projet .item:hover {
    transform: scale(1.05)
}

#projet .item h2 {
    font-weight: 600;
}

#projet #Jobboard {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(150, 150, 150, 1)),url("../../public/jobboard.png");
    background-size: cover;
}

#projet #IRC {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(150, 150, 150, 1)),url("../../public/irc.png");
    background-size:cover
}

#projet #Redditek {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(150, 150, 150, 1)),url("../../public/redditek.png");
    background-size:cover
}

#projet #Matchstick {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(150, 150, 150, 1)),url("../../public/matchsitck.png");
    background-size:cover
}

#projet #Need4Stek {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(150, 150, 150, 1)),url("../../public/Need4Stek.gif");
    background-size:cover
}

#projet #Groundhog {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(150, 150, 150, 1)),url("../../public/groundhog.png");
    background-size:cover
}

#projet #Groundhog h4 {
    text-align: left;
}

#projet #Sokoban {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(150, 150, 150, 1)),url("../../public/sokoban.png");
    background-size:cover
}

#projet #Ftp {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(150, 150, 150, 1)),url("../../public/myftp.png");
    background-size:cover
}

#projet #Ftp h4 {
    text-align: left;
}

#projet #IronMan {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(150, 150, 150, 1)),url("../../public/ironman.png");
    background-size:cover
}

#projet #Org {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(150, 150, 150, 1)),url("../../public/orga-theory.png");
    background-size:cover
}

.techno {
    display: flex;
    flex-direction: row;
}

.techno img {
    width: 11vh;
    margin-left: 2vh;
    padding:0.5vh;
}

.techno_content_txt {
    display: none;
}

@media (max-width: 768px) {
    #projet {
        padding-bottom: 5vh;
    }

    #projet .item {
        height: 35vh;
        width: 80vw;
        margin-bottom: 10vh;
        padding: 2vh
    }

    #projet .list-projet {
        flex-direction: column;
        align-content: center;
        margin-bottom: 4vh;
    }

    #projet .item h2 {
        margin-bottom: 0vh;
    }
    #projet .item h4 {
        margin-bottom: 0;
    }

    .techno img {
        width:10vh;
    }

    .techno_content {
        display:none;
    }

    .techno_content_txt {
        display: flex;
    }

    .techno_content_txt p {
        margin-right: 1vh;
    }

    .techno p {
        text-align: left;
        margin-bottom: 0;
    }

    .techno {
        flex-direction: column;
    }

    .list-projet {
        margin-top: 0vh;
    }
}