#Header {
    height: 56vw;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7)),url("../../public/fond.png");
    background-size: cover;
}

#memoji {
    height: 20vw;
}

.content {
    color: white;
    position: relative;
    height: 50vw;
    display:grid;
    justify-content: center;
    align-items: stretch;
    justify-items: center;
    align-content: center;

}

.content h1 {
    font-family: "Poppins";
    font-weight: 600;
}

.content h3 {
    font-family: "Poppins";
    font-weight: 400;
}

#learn_more {
    background-color:white;
    color: black;
    margin-top: 5vw;
    padding: 1.5vw 2.5vw;
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.5vw;
    border-radius: 10px;
    transition: 0.2s;
    scroll-behavior: smooth;
}

#learn_more:hover {
    background-color: #1484D4;
    color: white;
}


@media (max-width: 767px) {
    #Header {
        height: 100vh;
    }
    .content {
        height: 100vh;
        padding-top: 20vh;
        align-content:flex-start;
    }
    #memoji {
        height: 50vw;
        margin-top: 0vh;
        margin-bottom: 5vh;
    }
    #learn_more {
        padding: 1.5vh 2.5vh;
        font-size: 4vw;

    }
    .content h1, .content h3 {
        font-size: 7vw;
    }
}